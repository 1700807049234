import {
  SURTARANG,
  STEPUP,
  // TWOMINS2FAME,
  CLUBFEET,
  // BANDBOX,
  PANACHE,
  // IMPRESSION,
  // SHORTFILMMAKING,
  ARTANDCRAFT,
} from '../../constants/images';
import{
  Ankushdp,
  Asifdp,
} from '../../constants/images';
import {
  AbhijeetBhattacharya,
  Fossils,
  Cactus,
  Chandrabindu,
  Lopamudra,
  Nachiketa,
  Anjan_dutta,
  Anushka_manchanda,
  Armaan_malik,
  Ash_king,
  Benny_dayal,
  Dev_negi,
  Fakira,
  Indian_ocean,
  Javed_ali,
  Jonita_gandhi,
  KK,
  Krosswindz,
  kunal_ganjawala,
  Milind_gaba,
  Msonic,
  Muhammed_Irfan,
  Parikrama,
  Prithibi,
  Raeth,
  Raghu_dixit,
  Riddles,
  Rupankar,
  Shaan,
  usha_uttup,
  Vishal_dadlani,
  Jojo,
} from '../../constants/images';

export const anakhronosCompetetions = [
  {
    name: 'Sur Tarang',
    description: 'Solo Singing',
    image: SURTARANG,
    link: '',
  },
  {
    name: 'Step Up',
    description: 'Solo dance',
    image: STEPUP,
    link: '',
  },
  // {
  //   name: '2 Mins To Fame',
  //   description: 'Show case ur talent in 2 mins',
  //   image: TWOMINS2FAME,
  //   link: '',
  // },
  {
    name: 'Club Feet',
    description: 'The group dance',
    image: CLUBFEET,
    link: '',
  },
  // {
  //   name: 'Band Box',
  //   description: 'The battle of bands',
  //   image: BANDBOX,
  //   link: '',
  // },
  {
    name: 'Panache',
    description: 'Fashion show',
    image: PANACHE,
    link: '',
  },
  // {
  //   name: 'Impression',
  //   description: 'Photography competition',
  //   image: IMPRESSION,
  //   link: '',
  // },
  // {
  //   name: 'Short Film Making Competiton',
  //   description: 'Make short films',
  //   image: SHORTFILMMAKING,
  //   link: '',
  // },
  {
    name: 'ART AND CRAFT',
    description: 'Art and craft competition',
    image: ARTANDCRAFT,
    link: '',
  },
];

export const navLinks = [
  { name: 'Home', link: '/' },
  { name: 'About', link: '#anakhronos-about' },
  { name: 'Events', link: '#anakhronos-events' },
  { name: 'Artists', link: '#anakhronos-artists' },
  { name: 'Gallery', link: '#anakhronos-gallery' },
  { name: 'Merchandise', link: '#anakhronos-merchandise' },
  { name: 'Team', link: '#anakhronos-team' },
  { name: 'Sponsors', link: '#anakhronos-sponsors' },
  { name: 'Contact Us', link: '#anakhronos-contact' },
];

export const navLinksPrarambh = [
  { name: 'Home', link: '/' },
  { name: 'About', link: '#anakhronos-about' },
  { name: 'Events', link: '#anakhronos-events' },
  { name: 'Gallery', link: '#anakhronos-gallery' },
  { name: 'Team', link: '#anakhronos-team' },
  { name: 'Sponsors', link: '#anakhronos-sponsors' },
  { name: 'Contact Us', link: '#anakhronos-contact' },
];
export const navLinksAnakhronos = [
  { name: 'Home', link: '/' },
  { name: 'About', link: '#anakhronos-about' },
  { name: 'Events', link: '#anakhronos-events' },
  { name: 'Artists', link: '#anakhronos-artists' },
  { name: 'Merchandise', link: '#anakhronos-merchandise' },
  { name: 'Gallery', link: '#anakhronos-gallery' },
  { name: 'Team', link: '#anakhronos-team' },
  { name: 'Sponsors', link: '#anakhronos-sponsors' },
];

export const WebTeamData = [
  {
    name: 'Abhishek Chakraborty',
    description: 'Developer',
    image:
      'https://user-images.githubusercontent.com/73426684/229357783-30cc798d-e1fe-43e5-bfe5-71834a612854.jpeg',
    instagram: 'https://instagram.com/scribbling_bluebook?igshid=ZDdkNTZiNTM=',
    facebook: 'https://www.facebook.com/profile.php?id=100009085667915&mibextid=ZbWKwL',
    phone: '9674488512',
    linkedin: 'http://www.linkedin.com/in/abhishekchakraborty15',
  },
  {
    name: 'Akash Gupta',
    description: 'Developer',
    image:
      'https://user-images.githubusercontent.com/102872077/223781125-1dbb342f-4614-49b2-8da2-61460daf5a61.jpg',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Souvik Saha',
    description: 'Web Dev Head',
    image:
      'https://user-images.githubusercontent.com/102872077/223781678-6d54f946-16d4-470f-adde-b2fff3c63142.jpg',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Asif Ali',
    description: 'Developer',
    image: Asifdp,
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Ankush Banerjee ',
    description: 'Developer',
    image: Ankushdp,
    instagram: 'https://instagram.com/its_ankush_003?igshid=ZDdkNTZiNTM=',
    facebook: '',
    phone: '',
    linkedin: 'https://www.linkedin.com/in/ankush-banerjee-05b0b421a',
  },
  {
    name: 'Sreetama Ghosh Hazra',
    description: 'Web Dev Head',
    image:'https://user-images.githubusercontent.com/102872077/223780995-35f4c59b-0467-4d85-95c9-bb1246ab3dd9.jpg',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Rohit Kumar',
    description: 'Developer',
    image:'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/a2fe93b0-f76f-4304-8d72-12cca80507f3',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Debasmita Bedajna',
    description: 'Developer',
    image:'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/4941550b-e77e-4de2-a6cd-cca50871d961',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },
  {
    name: 'Sparsh Kumar Gupta',
    description: 'UI/UX Designer',
    image:'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/3fd4cb3c-24b5-4ee9-b706-99bb50c7da36',
    instagram: '',
    facebook: '',
    phone: '',
    linkedin: '',
  },

];

export const MemberData = [
  {
    name: "Akash Das",
    description: "Convenor",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/6909c81a-db34-4c2a-bec2-057d5c1adbcb',
    instagram: "https://instagram.com/_somya_shekhar?igshid=ZDdkNTZiNTM=",
    facebook: "https://www.facebook.com/rudrajit.sengupta?mibextid=LQQJ4d",
    phone: "",
    linkedin: "https://www.linkedin.com/in/rudrajit-sengupta-a67977227",
  },
  {
    name: "Harsh Kumar",
    description: "Convenor",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/4f4003df-d8b3-49d0-80a3-e88d6a62565e',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Bishakha Biswas",
    description: "Co-Convenor",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/e6286750-a2f8-40f9-9671-677bb86aff0a',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Akash Mondal",
    description: "Sponsorship Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/101ecc85-92a6-4e04-9f66-0c55c7785ce9',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Sayan Choudhury",
    description: "Sponsorship Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/66e906fa-b626-4d17-9c89-829c5b8f81c4',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Swarnali Datta",
    description: "Audition Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/42ee4e94-154c-4eb8-8939-d21f6c0d2149',
    instagram: "",
    facebook:
      "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Damayanti Sengupta",
    description: "Enigma Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/acc1b5d2-052b-4e76-8cd1-fae48da0e264',
    instagram: "",
    facebook:
      "",
    phone: "",
    linkedin: "",
  },
 /*  {
    name: "Soham Sur",
    description: "Inaugration Head",
    image: Soham,
    instagram: "https://instagram.com/the_melodiousboy?igshid=YmMyMTA2M2Y=",
    facebook: "https://www.facebook.com/soham.sur.94?mibextid=LQQJ4d",
    phone: "8584040052",
    linkedin: "",
  }, */
  /* {
    name: "Bidyayan Goswami",
    description: "Music Society Head",
    image: Bidyayan,
    instagram: "https://instagram.com/_the__percussionist?igshid=ZDdkNTZiNTM=",
    facebook: "https://www.facebook.com/profile.php?id=100009377223340&mibextid=ZbWKwL",
    phone: "7005174743",
    linkedin: "",
  }, */
  /* {
    name: "Oishi Panda",
    description: "Music Society Head",
    image: Oishi,
    instagram: "https://instagram.com/oishi__panda?igshid=ZDdkNTZiNTM=",
    facebook: "https://www.facebook.com/oishi.panda.14?mibextid=ZbWKwL",
    phone: "9330261884",
    linkedin: "https://www.linkedin.com/in/oishi-panda-753438197",
  }, */
  // --------------- to be added --------------------------------------------------------------------------------------
//   ![007](https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/ff9058db-ebf7-490c-a226-ff3ebaf32881) -- jeet 
// ![mt6](https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/e5f2a559-a4cf-4120-8851-f3dcd1a22ba8) -- sagnik 
// ![mt9](https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/dd96b20c-1364-4f5c-8453-b505b955156e) -- sayan
// --------------------------------------------------------------------------------------------------------------------
  {
    name: "Indrakshi Dutta",
    description: "Dance Society Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/e0027bca-6abe-4cb8-bad5-328e826c6f84',
    instagram: "",
    facebook:
      "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Priyadarshan Roy",
    description: "Media Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/b6a6c039-d8f2-4d0e-8349-1f1a036e313e',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Nancy Kashyap",
    description: "Editorial Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/f16b611f-9bbf-4fa2-b0e1-3a061f55ad01',
    instagram: "",
    facebook:
      "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Samata Mondal",
    description: "Registration Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/2b3a7256-d5ea-416e-b8a6-9264fa836525',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Sreetama Ghosh Hazra",
    description: "Web Development Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/47ddfc23-0c5a-490f-a04b-febeb74fe9bb',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Sagnik Bose",
    description: "Subscription Management Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/a0585f69-6896-4735-beec-582891d54900',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Niharika Mandal ",
    description: "TIFC Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/61a68fde-f405-44fe-80c0-10832f0e49c2',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },

  {
    name: "Bitanu Dey",
    description: "Food and Stationary Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/49598d25-5d6b-4c10-9da9-2124dd7c7f85',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Anurag Maitra",
    description: "Off Stage Events Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/d4d9bdfc-d996-4e4a-9c9b-da3e435025e7',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Atul Aditya",
    description: "Subscription Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/5a18f806-9a4e-4e60-917f-18612c381981',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  },
  {
    name: "Aniket das",
    description: "Co-Convenor",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/73426684/f414c834-aa87-436e-abf4-c1d8ed8483bf',
    instagram: "",
    facebook:
      "",
    phone: "",
    linkedin: "",
  },
  /* {
    name: "Ayan Ray",
    description: "Subscription Management Head",
    image: 'https://github.com/AnkuranTechno/anakhronos_new/assets/102872077/61a68fde-f405-44fe-80c0-10832f0e49c2',
    instagram: "",
    facebook: "",
    phone: "",
    linkedin: "",
  }, */
];

export const Artists = [
  {
    name: 'Chandrabindu',
    id: '#1',
    image: Chandrabindu,
    year: '2003, 2005',
  },
  {
    name: 'Lopamudra',
    id: '#2',
    image: Lopamudra,
    year: '2003',
  },
  {
    name: 'Miss Jojo',
    id: '#3',
    image: Jojo,
    year: '2003',
  },
  {
    name: 'Cactus',
    id: '#4',
    image: Cactus,
    year: '2004',
  },
  {
    name: 'Abhijeet Bhattacharya',
    id: '#5',
    image: AbhijeetBhattacharya,
    year: '2004',
  },
  {
    name: 'Nachiketa',
    id: '#6',
    image: Nachiketa,
    year: '2005',
  },
  {
    name: 'Fossils',
    id: '#7',
    image: Fossils,
    year: '2006',
  },
  {
    name: 'Shaan',
    id: '#8',
    image: Shaan,
    year: '2006',
  },
  {
    name: 'KK',
    id: '#9',
    image: KK,
    year: '2007',
  },
  {
    name: 'Krosswindz',
    id: '#10',
    image: Krosswindz,
    year: '2007',
  },
  {
    name: 'Prithibi',
    id: '#11',
    image: Prithibi,
    year: '2007',
  },
  {
    name: 'Kunal Ganjawala',
    id: '#12',
    image: kunal_ganjawala,
    year: '2008',
  },
  {
    name: 'Parikrama',
    id: '#13',
    image: Parikrama,
    year: '2008',
  },
  {
    name: 'Rupankar',
    id: '#14',
    image: Rupankar,
    year: '2009',
  },
  {
    name: 'Vishal Dadlani',
    id: '#15',
    image: Vishal_dadlani,
    year: '2009',
  },
  {
    name: 'Anjan Dutta',
    id: '#16',
    image: Anjan_dutta,
    year: '2009',
  },
  {
    name: 'Indian Ocean',
    id: '#17',
    image: Indian_ocean,
    year: '2009',
  },
  {
    name: 'Raghu Dixit',
    id: '#18',
    image: Raghu_dixit,
    year: '2010',
  },
  {
    name: 'Javed Ali',
    id: '#19',
    image: Javed_ali,
    year: '2011',
  },
  {
    name: 'Usha Uttup',
    id: '#20',
    image: usha_uttup,
    year: '2011',
  },
  {
    name: 'Benny Dayal',
    id: '#21',
    image: Benny_dayal,
    year: '2013',
  },
  {
    name: 'Muhammed Irfan',
    id: '#22',
    image: Muhammed_Irfan,
    year: '2014',
  },
  {
    name: 'Ash King',
    id: '#23',
    image: Ash_king,
    year: '2015',
  },
  {
    name: 'Raeth',
    id: '#24',
    image: Raeth,
    year: '2015',
  },
  {
    name: 'Armaan Malik',
    id: '#25',
    image: Armaan_malik,
    year: '2016',
  },
  {
    name: 'Anushka Manchanda',
    id: '#26',
    image: Anushka_manchanda,
    year: '2017',
  },
  {
    name: 'Fakira',
    id: '#27',
    image: Fakira,
    year: '2017',
  },
  {
    name: 'Jonita Gandhi',
    id: '#28',
    image: Jonita_gandhi,
    year: '2018',
  },
  {
    name: 'Msonic',
    id: '#29',
    image: Msonic,
    year: '2018',
  },
  {
    name: 'Dev Negi',
    id: '#30',
    image: Dev_negi,
    year: '2019',
  },
  {
    name: 'Riddles',
    id: '#31',
    image: Riddles,
    year: '2019',
  },
  {
    name: 'Milind Gaba',
    id: '#32',
    image: Milind_gaba,
    year: '2022',
  },
];